import { combineReducers } from 'redux';
import { BrandingReducer } from './branding';
import { CandidateReducer } from './candidate';
import { LanguageReducer } from './language';
import { ResetCandidateReducer } from './reset-candidate';
import { AssessmentReuseReducer } from './assessmentReuse';
import { HeaderReducer } from './header';
import { SessionReducer } from './session';
import { DemographicsReducer } from './demographics';
import { RedirectWarningReducer } from './redirect-warning';
import { DynamicFeedbackReducer } from './dynamicFeedbackOverlay';
import * as State from './state';
import { ReducersMapObject } from 'redux';

/**
 * Parent reducer to manage all of the reducer composition,
 * Note: the mapping between reducer and name is required to match State.All,
 * otherwise the state for the application doesn't match, this is reducer composition.
 */
export const AppReducer = combineReducers<State.All>(<ReducersMapObject> {
    candidate: CandidateReducer,
    language: LanguageReducer,
    branding: BrandingReducer,
    resetCandidate: ResetCandidateReducer,
    assessmentReuse: AssessmentReuseReducer,
    header: HeaderReducer,
    session: SessionReducer,
    demographics: DemographicsReducer,
    redirectWarning: RedirectWarningReducer,
    dynamicFeedback: DynamicFeedbackReducer
});