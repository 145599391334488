import IntlProviderContainer from '../../components/intl-provider-container';
import * as Containers from "../redux-containers";
import { AssessmentModel, AssessmentStatus } from '../../models/assessment';
import { getLaunchAssessmentUrl } from '../../services/candidate-service';
import { RouterMetadata } from '../higher-order/with-router';
import { useEffect, useRef, useState } from 'react';
import { logErrorSilent } from '../../misc/error';
import { CmsText } from '../../misc/cms-text';
import { Loading } from '../common/loading/loading';
import TalviewProctoringManager from '../../services/talview-service';
import { PersonModel } from '../../models/candidate';
import AppConstants from "../../constants/app";

/**
 * State props for the CompletedAssessmentsMessage component
 */
export interface AssessmentPlayerState {
    assessments: AssessmentModel[],
    personal: PersonModel,
    text: CmsText
}

export interface AssessmentPlayerProps {
    router: RouterMetadata
}

type AssessmentPlayerAllProps = AssessmentPlayerState & AssessmentPlayerProps;


/**
 * Method to return elements for dashboard page
 */
export const AssessmentPlayer = (props: AssessmentPlayerAllProps): JSX.Element => {
    console.log(`%c[Assessment Player]`, 'color: orange', props);
    
    const { text, router, assessments, personal } = props;
    const { assessmentId, languageId } = router.params;

    const [ assessmentUrl, setAssessmentUrl ] = useState('');
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(false);
    const iframeRef = useRef<HTMLIFrameElement>(null);
    
    const assessment = assessments.find(a => a.assessmentId === Number(assessmentId));
    const sessionStorageKeys = AppConstants.Proctoring.SessionStorageKeys;

    const redirectToDashboard = () => {
        history.pushState(null, '', '/');
        history.go();
    }

    const handleAssessmentLoad = () => {
        if (!iframeRef.current) return;
        sessionStorage.setItem(sessionStorageKeys.PROCTORED_ASSESSMENT_URL, iframeRef.current.contentWindow?.location.href ?? '');
    }

    useEffect(() => {

        const launchProctoredAssessment = async (assessmentId: string | undefined, languageId: string | undefined) => {
        
            //Getting assessment launch url
            const result = await getLaunchAssessmentUrl(Number(assessmentId), Number(languageId));
        
            if (!result) {
                throw new Error('Unable to launch assessment, no assessment launch details found');
            } else if (result.status === AssessmentStatus.Completed) {
                throw new Error('Unable to launch assessment, assessment complete');
            } else if (!result.launchUrl) {
                throw new Error('Unable to launch assessment, no url found');
            } else {
                
                try {

                    const additionalInstructions = text.get(
                        'proctoring.preflight.additionalInstructions', 
                        'Preflight additional instructions'
                    );
                    const proctoringManager = new TalviewProctoringManager(
                        {
                            token: assessment?.proctoringToken,
                            profileId: personal.id.toString(),
                            session: assessment?.scope,
                            sessionTitle: assessment?.scope,
                            sessionType: 'ai_proctor',
                            scriptUrl: process.env.REACT_APP_Proctoring_Script_URL,
                            additionalInstructions
                        }
                    );
                
                    await proctoringManager.init();
    
                    const proctoringSessionId = await proctoringManager.start();
                    
                    await proctoringManager.saveSessionDetails(
                        { 
                            sessionUuid: proctoringSessionId, 
                            assessmentId: Number(assessmentId) 
                        }
                    );
    
                    const cachedUrl = sessionStorage.getItem(sessionStorageKeys.PROCTORED_ASSESSMENT_URL);
                    const assessmentUrl = (cachedUrl) ? cachedUrl : result.launchUrl;
                    
                    setAssessmentUrl(assessmentUrl);
                    setLoading(false);
                    
                    return result;

                } catch (error) {
                    throw new Error(`Unable to launch assessment: ${error.message}`);
                }
            }
        }
        
        launchProctoredAssessment(assessmentId, languageId).then(result => {
            console.debug('Proctored Launch Result:', result);
        }).catch(error => {
            const canNotLaunchErrorText = text.get('launchAssessment.canNotLaunchError', 'Cannot launch assessment');
            logErrorSilent(`${canNotLaunchErrorText}: ${error.message}`);
            console.error(error.message);
            setError(true);
        });
        
        return () => {
            const cleanUp = async () => {
                console.log('%cCleaning up proctoring manager', 'color: yellow');
                sessionStorage.removeItem(sessionStorageKeys.PROCTORED_ASSESSMENT_URL);
                redirectToDashboard();
            }
            cleanUp();
        }

    }, [error]);

    return (loading) ? <Loading /> : (
        <IntlProviderContainer>
            <div className="main-layout proctored">
                <iframe
                    ref={iframeRef}
                    src={assessmentUrl}
                    onLoad={handleAssessmentLoad}
                    className='proctored-assessment-player'
                />
            </div>
        </IntlProviderContainer>
    );
};

export default Containers.createStateWithProps<AssessmentPlayerState, AssessmentPlayerProps>(AssessmentPlayer, (state, props) => ({
    assessments: state.candidate.assessments!,
    personal: state.candidate.personal!,
    text: new CmsText(state.language.alltext, 'AssessmentPlayer', 'dashboard'),
    router: props.router
}))
