import * as Containers from '../../redux-containers';
import { ResetCandidate } from '../../../actions/reset-candidate';
import { hideMenu } from '../../../actions/header';
import { PermissionsModel, CandidateContactInfo } from '../../../models/candidate';
import { NoJsxElement, RedirectToLogin, NullableElement } from '../../../misc/common';
import { clearSession } from '../../../misc/session';
import { CmsText } from '../../../misc/cms-text';
import { logout } from '../../../services/authorize-service';
import { hasBranding, getBranding } from '../../../misc/brandingSession';
import { useEffect } from 'react';

/**
 * State props for the Navbar component.
 */
export interface NavbarStateProps {
    candidatePermissions: PermissionsModel;
    resetting: boolean;
    candidateIsLoaded: boolean;
    candidateIsRefreshing: boolean;
    candidateContactInfo: CandidateContactInfo;
    text: CmsText;
    languageId: number;
}

/**
 * Dispatch props for the Navbar component.
 */
export interface NavbarDispatchProps {
    resetCandidate: () => void;
    hideMenu: () => void;
}

/**
 * Combined typed props for Navbar component
 */
export type NavbarProps = NavbarStateProps & NavbarDispatchProps;

/**
 * Renders Navbar
 */
export const Navbar = (props: NavbarProps) => {

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            props.hideMenu();
        }
    };

    const resetCandidate = (): NullableElement => {
        if (props.candidatePermissions !== undefined &&
            props.candidatePermissions.canResetAssessments) {
            return (
                <a href="#" className="hamburger-menu-content-item" role="menuitem" onClick={(event) => { props.resetCandidate() }}>
                    {props.text.get('resetCandidate', 'Reset candidate')}
                </a>
            );
        }
        return NoJsxElement;
    };

    const signOut = (): void => {
        logout()
            .then(() => {
                let brandingName = '';
                if (hasBranding()) {
                    brandingName = getBranding().name;
                }
                clearSession();
                RedirectToLogin(brandingName, props.languageId);
            });
    };

    const signOutSection = (): NullableElement => {
        if (props.candidateIsLoaded || props.candidateIsRefreshing) {
            return (
                <a href="#" role="menuitem" className="hamburger-menu-content-item" onClick={(event) => { signOut(); }}>
                    {props.text.get('signOut', 'Sign Out')}
                </a>
            );
        }
        return NoJsxElement;
    };

    const candidateContactInfo = (): NullableElement => {
        if (props.candidateContactInfo !== undefined && props.candidateContactInfo.hasInfo) {
            return (
                <div className="hamburger-menu-content-item" role="menuitem">
                    <div>
                        <b>{props.text.get('candidateContact', 'For assistance, please contact:')}</b>
                    </div>
                    <div className="hamburger-menu-content-contact">
                        <div>
                            {props.candidateContactInfo.contactName}
                        </div>
                        <div>
                            {props.candidateContactInfo.contactNumber}
                        </div>
                        <div>
                            {props.candidateContactInfo.contactEmail}
                        </div>
                    </div>
                </div>
            );
        }
        return NoJsxElement;
    };

    const handleBlur = (event: React.FocusEvent<HTMLElement>) => {
        if (!event.relatedTarget || !event.currentTarget.contains(event.relatedTarget)) {
            props.hideMenu()
        }
    }

    /**
     * Dashboard side menu section. 
     */
    return (
        <div onBlur={handleBlur} role="menu" className="hamburger-menu-content" id="hamburger-menu-content">
            {candidateContactInfo()}
            {resetCandidate()}
            {signOutSection()}
        </div>
    );
};

export default Containers.createStateAndDispatch(
    Navbar,
    (state) => ({
        candidatePermissions: state.candidate.permissions,
        resetting: state.resetCandidate.resettingCandidate,
        candidateIsLoaded: state.candidate.isLoaded,
        candidateIsRefreshing: state.candidate.isRefreshing,
        candidateContactInfo: state.candidate.candidateContactInfo,
        text: new CmsText(state.language.alltext, 'NavBar', 'navBarMenu'),
        languageId: state.language.languageId
    }),
    (dispatch) => ({
        resetCandidate: () => dispatch<any>(ResetCandidate()),
        hideMenu: () => dispatch<any>(hideMenu())
    })
);
