import React from 'react';
import * as Containers from '../redux-containers';
import { toggleMenu, hideMenu } from '../../actions/header';
import Navbar from './navbar/navbar';
import { NoJsxElement, NullableElement } from '../../misc/common';
import classNames from 'classnames';
import { CmsText } from '../../misc/cms-text';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import SiteLanguage from '../common/site-language';

/**
 * Dispatch own props for the header component.
 */
interface HeaderOwnProps {
  showHumburgerMenuIcon?: boolean;
  showLanguageSelectDropdown?: boolean
}
/**
 * Dispatch props for the header component.
 */
export interface HeaderDispatchProps {
  toggleMenu: () => void;
  hideMenu: () => void;
}

/**
 * State props for the Header component.
 */
export interface HeaderStateProps {

  /**
   * The language of the site
   */
  languageId: number;
  showMenu: boolean;
  logoUrl: string;
  name: string | undefined;
  text: CmsText;
}

/**
 * Combined typed props for Header component
 */
export type HeaderProps = HeaderOwnProps & HeaderStateProps & HeaderDispatchProps;

/**
 * Renders the page header
 */
export const Header = (props: HeaderProps) => {
  const headerRef = React.useRef<HTMLDivElement>(null);

  const hamburgerKeyHandler = (event: any) => {
    if (event.key === ' ' || event.key === 'Spacebar' || event.key === 'Enter') {
      event.preventDefault();
      props.toggleMenu();
    }
  };

  const navbarMenu = (): NullableElement => {
    if (props.showMenu) {
      return <Navbar />;
    }
    return NoJsxElement;
  };

  let menuIconClass = classNames({
    'hamburger-menu-icon': props.showHumburgerMenuIcon,
    'hamburger-menu-icon-active': props.showMenu
  });

  let logoUrl: string = '/images/logo-kornferry.svg';
  let defaultAltText: string = 'Korn Ferry';

  let logoUrlIsDefault: boolean = true;
  if (props.logoUrl !== null && props.logoUrl.length > 0) {
    logoUrl = props.logoUrl;
    logoUrlIsDefault = false;
  }
  
  // For accessibility purpose, we need to match the alt text with the logo
  let brandName: string = logoUrlIsDefault ? defaultAltText : props.name || defaultAltText;

  useOutsideClick(headerRef, props.hideMenu)

  return (
    <header ref={headerRef}>
      <a href="#main-content" className="skip-to-main-content-link">Skip to main content</a>
      <div className="navigation-brand-image">
        <img src={logoUrl} className={logoUrlIsDefault ? 'img-margin' : ''} alt={brandName} />
      </div>
      <nav className="navbar navbar-light justify-content-end">
        <img className="navbar-brand" alt="" />
        {props.showLanguageSelectDropdown && (
          <div className="navbar-languages-container">
            <SiteLanguage />
          </div>
        )}
        {props.showHumburgerMenuIcon && (
          <div className="navbar-divider-horizontal"></div>
        )}
        <div className="hamburger-menu hamburger-menu-active">
          <div className="hamburger-menu-icon-container">
            <span id="menuButtonAdditionalInfo" className="no-display">
              : {props.text.get('navigateInstruction', 'Press the enter key to  expand and use the arrow keys to navigate')}
            </span>
            <button 
              className={menuIconClass} 
              aria-label={props.text.get('hamburgerMenuLabel', 'Menu')}
              aria-expanded={props.showMenu}
              aria-controls="hamburger-menu-content"
              aria-haspopup="menu" 
              onClick={props.toggleMenu}
              onKeyDown={hamburgerKeyHandler}
              >
              <span />
              <span />
              <span />
            </button>
          </div>
          {navbarMenu()}
        </div>
      </nav>
    </header>
  );
};

export default Containers.createStateAndDispatchWithProps<HeaderStateProps, HeaderDispatchProps, HeaderOwnProps>(
  Header,
  (state, props) => ({
    languageId: state.language.languageId,
    showMenu: state.header.showMenu,
    logoUrl: state.branding.logoUrl,
    text: new CmsText(state.language.alltext, 'NavBar', 'navBarMenu'),
    name: state.branding.name,
    showHumburgerMenuIcon: props.showHumburgerMenuIcon,
  }),
  (dispatch) => ({
    toggleMenu: () => dispatch<any>(toggleMenu()),
    hideMenu: () => dispatch<any>(hideMenu())
  })
);
