import React from 'react';
import * as Containers from '../redux-containers';
import { AssessmentModel, AssessmentStatus, CompatibilityStatus } from '../../models/assessment';
import { CmsText } from '../../misc/cms-text';
import { NoJsxElement, NullableElement } from '../../misc/common';
import SiteLanguage from '../common/site-language';
import { AsyncButton } from '../common/async-button';
import { showReuseAssessment } from '../../actions/assessmentReuse';
import { launchAssessment } from '../../misc/assessment';

/**
 * Own props for the Launch assessment component
 */
interface LaunchAssessmentOwnProps {
    /**
     * The assessment to launch.
     */
    assessment: AssessmentModel;

    /**
     * Text for aria label
     */
    assessmentName?: string;
}

interface LaunchAssessmentStateProps {
    /**
     * Component Text
     */
    text: CmsText;

    /**
     * Component accessibility text
     */
    accessibilityText: CmsText;
    /**
     * The language id for the current site text.
     */
    siteLanguageId: number;

    /**
     * Specifies if dashboard is being accessed to preview branding styles
     */
    isPreviewMode: boolean;
}

interface LaunchAssessmentDispatch {
    openReuseAssessment: (assessment: AssessmentModel) => void;
}

type LaunchAssessmentAllProps = LaunchAssessmentOwnProps & LaunchAssessmentStateProps & LaunchAssessmentDispatch;

type ActionButtonType = {
    Launch: string,
    Continue: string
}

const ActionButtons: ActionButtonType = {
    Launch: "launch",
    Continue: "continue"
}

/**
 * Renders a launch assessment component that starts the assessment in the Reflex system.
 * @param props The properties to pass to the component
 */
export class LaunchAssessment extends React.Component<LaunchAssessmentAllProps> {
    constructor(props: LaunchAssessmentAllProps) {
        super(props);
        this.launchAssessment = this.launchAssessment.bind(this);
    }

    getCompatibilityText(): NullableElement {
        switch (this.props.assessment.compatability) {
            case CompatibilityStatus.CompatibleWithWarning:
                return <span>{this.props.text.get('compatibility.compatibleWarning', 'This device is not recommended.')}</span>;
            default:
                return NoJsxElement;
        }
    }

    getActionMessage(): NullableElement {
        // The assessment isn't enabled, due to exceeding the maximum number of attempts for an ability test.
        if (!this.props.assessment.isEnabled) {
            return <div>{this.props.text.get('maxAttemptsExceeded', 'In progress. Please contact your administrator to re-enable')}</div>;
        }

        return NoJsxElement;
    }

    /**
     * Check if user can launch assessment.     
     */
    showLaunchAction(): boolean {
        if (this.props.assessment.isLocked || !this.props.assessment.isEnabled) {
            return false;
        } else {
            return true;
        }
    }

    changeLanguageRequired(): boolean {

        if (this.props.assessment.isLocked ||
            !this.props.assessment.isEnabled ||
            this.props.assessment.status !== AssessmentStatus.NotStarted ||
            this.props.assessment.languageId > 0) {
            return false;
        }

        if (!this.props.assessment.supportedLanguageIds.some(l => l === this.props.siteLanguageId)) {
            return true;
        }

        return false;
    }

    launchAssessment(): Promise<any> {
        if (this.props.assessment.reuseAssessmentAvailable) {
            this.props.openReuseAssessment(this.props.assessment);
            return Promise.resolve();
        }

        return launchAssessment(this.props.assessment, this.props.siteLanguageId, this.props.text);
    }

    getActionButtonAriaLabel(actionButtonType: string): string {
        let defaultValue = "";
        const { accessibilityText, assessmentName } = this.props;
        
        if (actionButtonType === ActionButtons.Launch) {
            defaultValue = "Begin ${assessmentName} assessment";
        } else if (actionButtonType === ActionButtons.Continue) {
            defaultValue = "Continue ${assessmentName} assessment" 
        }

        const ariaLabelText = accessibilityText.get(`launchAssessment.${actionButtonType}`, defaultValue);
        
        return accessibilityText.resolveTokens(ariaLabelText, { assessmentName });
    }

    render() {
        const a = this.props.assessment;
        const isAssessmentStarted = (a.status !== AssessmentStatus.NotStarted);

        // The candidate can start or continue the assessment.
        let actionButtonLabel = (!isAssessmentStarted) ? this.props.text.get('launch', 'Launch') : this.props.text.get('continue', 'Continue');
        actionButtonLabel = a.reuseAssessmentAvailable ? this.props.text.get('reuse.resubmitRetakeButton', 'Resubmit / Retake') : actionButtonLabel;
        const actionButtonAriaLabel = (!isAssessmentStarted) ? this.getActionButtonAriaLabel(ActionButtons.Launch) : this.getActionButtonAriaLabel(ActionButtons.Continue);
        if (this.props.isPreviewMode) {
            return (
                <div className="assessment-container-action__panel">
                    <span className="assessment-container-action-start">
                        <AsyncButton
                            className="assessment-container-action-start-button"
                            ariaLabelText={ actionButtonAriaLabel }
                            disabled={ true }
                            clickEvent={ this.launchAssessment }
                        >
                            { actionButtonLabel }
                        </AsyncButton>
                    </span>
                </div>
            );
        }

        // Device is not compatible
        if (a.compatability === CompatibilityStatus.NotCompatible) {
            return (
                <div className="assessment-container-action__panel" aria-describedby="incomplete-assessments-grid-title-column-action">
                    <span className="assessment-container-action-incompatible">
                        <span className="dashboard-icon dashboard-icon-not-supported" />
                        { this.props.text.get('compatibility.notCompatible', 'Not compatible') }
                    </span>
                </div>
            );
        }
        if (this.changeLanguageRequired()) {
            return (
                <div className="assessment-container-action__panel" aria-describedby="incomplete-assessments-grid-title-column-action">
                    <span className="assessment-container-action-language">
                        <span>{ this.getCompatibilityText() }</span>
                        <span>{ this.getActionMessage() }</span>
                        <span>{ this.props.text.get('languageNotAvailable', 'Not available in your language') }</span>
                        <SiteLanguage
                            disabled={!a.isEnabled || a.isLocked}
                            supportedLanguageIds={this.props.assessment.supportedLanguageIds}
                            pleaseSelectText={this.props.text.get('selectLanguage', 'Select Language')}
                        />
                    </span>
                </div>
            );
        }

        // Assessment is disabled or locked
        if (!this.showLaunchAction()) {
            return (
                <div className="assessment-container-action__panel" aria-describedby="incomplete-assessments-grid-title-column-action">
                    <span className="assessment-container-action-locked">
                        { this.getActionMessage() }
                    </span>
                </div>
            );
        }

        return (
            <div className="assessment-container-action__panel">
                <span className="assessment-container-action-start">
                    {this.getCompatibilityText()}
                    <AsyncButton
                        className="assessment-container-action-start-button"
                        ariaLabelText={ actionButtonAriaLabel }
                        disabled={ false }
                        clickEvent={ this.launchAssessment }
                    >
                        { actionButtonLabel }
                    </AsyncButton>
                </span>
            </div>
        );
    }
}

export default Containers.createStateAndDispatchWithProps<LaunchAssessmentStateProps, LaunchAssessmentDispatch, LaunchAssessmentOwnProps>(
    LaunchAssessment,
    (state) => ({
        siteLanguageId: state.language.languageId,
        isPreviewMode: state.candidate.isPreviewMode,
        text: new CmsText(state.language.alltext, 'LaunchAssessment', 'dashboard.launchAssessment'),
        accessibilityText: new CmsText(state.language.alltext, 'LaunchAssessment', 'dashboard.accessibility')
    }),
    (dispatch) => ({
        openReuseAssessment: (assessment: AssessmentModel) => dispatch<any>(showReuseAssessment(true, assessment))
    })
);
